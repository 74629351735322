<template>
  <div
    v-if="brandId"
  >
    <div
      v-if="showSpinner"
      class="position-absolute d-flex align-items-center w-100 justify-content-center h-100 spinner-background"
    >
      <Spinner />
    </div>
    <div>
      <div class="container row justify-content-center">
        <div class="col-6">
          <div class="d-flex align-items-center gap-2 pt-2">
            <div>
              Please upload your csv
            </div>
            <input
              ref="attachedFile"
              type="file"
              hidden
              accept=".csv"
              @change="handleFileValidate"
            />
            <button
              class="btn btn-primary btn-sm submit-forecast-button"
              :disabled="isUploadBtnDisable"
              @click="$refs.attachedFile.click()"
            >
              Upload
            </button>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <span>(You can upload the latest version of the CSV</span>
            <span class="mx-1">
              <a
                class="blue-link d-inline"
                role="button"
                @click="getForecastTemplate"
              >
                here
              </a>
            </span>
            <span>and a set of instructions</span>
            <span class="mx-1">
              <a
                class="blue-link d-inline"
                role="button"
                @click="downloadForecastInstructionFile"
              >
                here
              </a>
            </span>
            <span>.)</span>
          </div>
          <div class="d-flex justify-content-center">
            <DatePicker
              v-model="selectedDate"
              class="narrow-input pt-3 mx-2"
              :format="dateFormatter"
              :errors="v$.selectedDate.$errors"
              description="Choose first date of forecast for uploaded file"
              label="Forecast first date"
              @blur="v$.selectedDate.$touch"
            />
            <SelectInput
              v-model="selectedLocation"
              class="narrow-input pt-3 mx-5"
              :options="locationsOptions"
              :errors="v$.selectedLocation.$errors"
              description="Choose location for the uploaded forecast"
              label="Forecast location"
              @blur="v$.selectedLocation.$touch"
            />
          </div>
          <div class="mt-3">
            <h6>Here are a few rules to follow when uploading your forecast:</h6>
            <ul class="rules-list">
              <li>
                Ecom “Units” and “Orders” include LTM. LTM is then split into “Laser” and “Embroidery”.
              </li>
              <li>
                POs (for Wholesale or Receiving) are reported weekly. There should be only one per line, on any line of
                the week.
              </li>
              <li>
                There are three types of Receiving that are added together: “Pallet”, “Carton”, and “Unit”. For each,
                enter only the quantity of Units for that type.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="forecastTableVisible"
        class="container mt-4"
      >
        <div class="row">
          <div class="col-md-8 p-1 border position-relative">
            <div
              v-if="aiRequestInProcess"
              class="spinner-table-overlay"
            >
              <Spinner />
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="table-light">
                  <tr>
                    <th>Week No</th>
                    <th>Date</th>
                    <th>Ecom Orders</th>
                    <th>Ecom Units</th>
                    <th>Description</th>
                    <th>LTM Laser</th>
                    <th>LTM Embroidery</th>
                    <th>Returns</th>
                    <th>Wholesale</th>
                    <th>Wholesale PO</th>
                    <th>Start Date</th>
                    <th>Cancel Date</th>
                    <th>Receiving PO</th>
                    <th>Containers</th>
                    <th>Pallet</th>
                    <th>Carton</th>
                    <th>Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in forecastAIData.forecasts"
                    :key="index"
                  >
                    <td>
                      <input
                        v-model="row.weekNo"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.date"
                        class="form-control"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.ecommerceOrders"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.ecommerceUnits"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <textarea
                        v-model="row.ecommerceDescription"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.ltmLaser"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.ltmEmbroidery"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.returns"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.wholesale"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <textarea
                        v-model="row.wholesalePO"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.wholesaleStartDate"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.wholesaleCancelDate"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <textarea
                        v-model="row.receivingPO"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.receivingContainers"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.receivingPallet"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.receivingCarton"
                        class="form-control"
                      />
                    </td>
                    <td>
                      <input
                        v-model="row.receivingUnit"
                        class="form-control"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-4 p-3 border chat-container">
            <div class="message-container">
              <div v-if="aiRequestInProcess">
                <div
                  v-for="(msg, index) in processingMessages"
                  :key="index"
                  class="chat-message system-message"
                >
                  <div class="alert alert-success">
                    <p>{{ msg }}</p>
                  </div>
                </div>
              </div>
              <div v-if="!aiRequestInProcess">
                <div
                  v-if="isSubmitted && !isForecastChanged"
                  class="chat-message alert alert-success"
                >
                  <div class="system-message">
                    Your forecast from {{ forecastAIData.firstDate }} to {{ forecastAIData.lastDate }} was successfully submitted, thank you!
                  </div>
                </div>

                <div v-else-if="allRequirementsMessageVisible">
                  <div class="chat-message alert alert-success">
                    <div class="system-message">
                      Your forecast meets all the requirements. You can either continue editing it or submit now.
                    </div>
                  </div>
                  <div
                    v-if="isSubmitBtnVisible"
                    class="user-response"
                  >
                    <button
                      class="btn btn-success btn-sm"
                      @click="submitForecast"
                    >
                      Submit Now
                    </button>
                  </div>
                </div>

                <div v-else-if="issuesInitially">
                  <div class="chat-message alert alert-danger">
                    <div class="system-message">
                      Please check your forecast for the period from {{ forecastAIData.firstDate }} to {{ forecastAIData.lastDate }} to the left.
                      We noticed that there was an issue.
                    </div>
                  </div>
                </div>

                <div v-if="forecastAIData.lessThan15DaysOfFutureMonth">
                  <div class="chat-message alert alert-warning">
                    <div class="system-message">
                      You have included in your forecast less than 2 weeks of the month of April. We have removed them.
                      Do you want to re-submit your forecast with the full month of April included in the forecast.
                    </div>
                  </div>
                  <div class="user-response">
                    <input
                      ref="attachedFile"
                      type="file"
                      hidden
                      accept=".csv"
                      @change="handleFileValidate"
                    />
                    <button
                      class="btn btn-primary btn-sm"
                      :disabled="isUploadBtnDisable"
                      @click="$refs.attachedFile.click()"
                    >
                      Yes
                    </button>
                    <button
                      class="btn btn-secondary btn-sm"
                      @click="setLessThan15DaysOfFutureMonth(false)"
                    >
                      No
                    </button>
                  </div>
                </div>

                <div v-if="forecastAIData.missingFewDaysOfFutureMonth">
                  <div class="chat-message alert alert-warning">
                    <div class="system-message">
                      Your forecast included most of {{ getNextMonthName }}, but you are missing a few days at the end of the month.
                      Please add it to your forecast to the left. If you are done, click to re-check your forecast.
                    </div>
                  </div>
                </div>

                <div
                  v-if="forecastAIData.ltmHigherThanEcom"
                  class="chat-message alert alert-danger"
                >
                  <div class="system-message">
                    Because Ecom units (or orders) must include LTM units (or orders), Ecom must be larger than LTM.
                    But that is not the case on some days in your forecast.
                    Please edit your forecast to fix this issue. When you are done, click to re-check your forecast.
                  </div>
                </div>

                <div
                  v-if="forecastAIData.multiplePOsDetected"
                  class="chat-message alert alert-warning"
                >
                  <div class="system-message">
                    We noticed there were multiple POs listed in either Wholesale or Receiving.
                    We have split this up into different lines.
                    Please edit the Wholesale or Receiving quantities to reflect this in the forecast to the left.
                    When you are done, click to re-check your forecast.
                  </div>
                </div>

                <div
                  v-if="forecastAIData.missingPONumber"
                  class="chat-message alert alert-danger"
                >
                  <div class="system-message">
                    We noticed you entered quantities but no POs in either Wholesale or Receiving.
                    Please add the Wholesale or Receiving POs that match your entered quantities in the forecast to the left.
                    When you are done, click to re-check your forecast.
                  </div>
                </div>

                <div v-if="forecastAIData.multipleReceivingEntries">
                  <div class="chat-message system-message alert alert-warning system-message">
                    We noticed that  you entered quantities for more than 1 type of Receiving.
                    Remember, the amounts in “Pallet”, “Carton” and “Unit” are added together and represent total Receiving.
                    You can either edit your forecast on the left of, if you are done click submit.
                  </div>
                  <div class="user-response">
                    <button
                      v-if="isSubmitBtnVisible"
                      class="btn btn-success btn-sm"
                      @click="submitForecast"
                    >
                      Submit Now
                    </button>
                  </div>
                </div>
                <div v-else-if="isForecastChanged && !issuesInitially">
                  <div class="chat-message alert alert-warning">
                    <div class="system-message">
                      You updated forecast. Please click on the button to recheck.
                    </div>
                  </div>
                </div>

                <div v-if="isRecheckBtnVisible">
                  <div class="user-response">
                    <button
                      class="btn btn-warning btn-sm"
                      @click="handleRecheckForecast"
                    >
                      Re-check my Forecast
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="serverValidationErrors.length > 0"
    class="error-overlay pt-lg-5"
  >
    <div class="error-card">
      <div class="card-header d-flex justify-content-between bg-danger">
        <span>File validation errors</span>
        <button
          class="btn-close text-white"
          @click="serverValidationErrors = []"
        />
      </div>
      <div class="card-body w-100 p-5 bg-opacity-75 bg-body">
        <p
          v-for="(error, index) in serverValidationErrors"
          :key="index"
        >
          <span class="font-weight-bold">{{ index + 1 }}</span>. {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/common/Spinner.vue';
import DatePicker from '@/components/common/DatePicker.vue';
import SelectInput from '@/components/common/SelectInput.vue';
import { dateFormatter } from '@/components/constants';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { processingAIMessages } from './constants';

export default {
  components: { SelectInput, DatePicker, Spinner },
  props: ['brandId'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      newMessage: '',
      aiRequestInProcess: false,
      serverValidationErrors: [],
      uploadingInProgress: false,
      selectedFile: null,
      selectedLocation: null,
      selectedDate: null,
      activeIssue: null,
      isSubmitted: false,
      processingMessages: [],
      isForecastChanged: false,
      unchangedForecast: null
    };
  },
  validations() {
    return {
      selectedDate: { required: required },
      selectedLocation: { required: required }
    };
  },
  computed: {
    ...mapGetters({
      forecastAIData: 'forecast/aiForecast',
      locations: 'brandManagement/brandLocations'
    }),
    getNextMonthName() {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);

      return nextMonth.toLocaleString('en-US', { month: 'long' });
    },
    isUploadBtnDisable() {
      return this.aiRequestInProcess;
    },
    isSubmitBtnVisible() {
      return !this.hasRecheckIssue && !this.isForecastChanged;
    },
    forecastTableVisible() {
      return this.forecastAIData.forecasts.length > 0 || this.aiRequestInProcess;
    },
    issuesInitially() {
      return this.forecastAIData.missingFewDaysOfFutureMonth ||
        this.forecastAIData.ltmHigherThanEcom ||
        this.forecastAIData.multiplePOsDetected ||
        this.forecastAIData.missingPONumber ||
        this.forecastAIData.lessThan15DaysOfFutureMonth ||
        this.forecastAIData.multipleReceivingEntries;
    },
    allRequirementsMessageVisible() {
      return !this.issuesInitially && !this.isForecastChanged;
    },
    isRecheckBtnVisible() {
      return this.hasRecheckIssue || this.isForecastChanged;
    },
    hasRecheckIssue() {
      return (
        this.forecastAIData.missingFewDaysOfFutureMonth ||
        this.forecastAIData.ltmHigherThanEcom ||
        this.forecastAIData.multiplePOsDetected ||
        this.forecastAIData.missingPONumber
      );
    },
    showSpinner() {
      return this.uploadingInProgress;
    },
    locationsOptions() {
      return this.locations.map((item) => ({ key: item, value: item }));
    },
  },
  watch: {
    'forecastAIData.forecasts': {
      handler(newVal) {
        if (newVal
          && this.unchangedForecast
          && JSON.stringify(newVal) !== JSON.stringify(this.unchangedForecast)
          && newVal[0]?.date === this.unchangedForecast[0]?.date) {
          this.isForecastChanged = true;
        } else if (newVal && JSON.stringify(newVal) === JSON.stringify(this.unchangedForecast)) {
          this.isForecastChanged = false;
        }
      },
      deep: true,
    }
  },
  async mounted() {
    const result = await this.fetchAiForecastChat({ brandId: this.brandId });
    this.unchangedForecast = { ...result.forecasts };
    this.preselectDate();
  },
  methods: {
    ...mapActions({
      fetchBrandLocations: 'brandManagement/fetchBrandLocations',
      generateForecast:'forecast/generateAIForecast',
      fetchAiForecastChat: 'forecast/fetchAiForecastChat',
      uploadAIForecast: 'forecast/uploadAIForecast',
      downloadForecastTemplate: 'forecast/getForecastTemplate',
      validateForecast: 'forecast/validateForecast',
      recheckForecast: 'forecast/recheckForecast',
      downloadForecastInstructionFile: 'forecast/downloadForecastInstructionFile'
    }),
    setLessThan15DaysOfFutureMonth(boolean) {
      this.forecastAIData.lessThan15DaysOfFutureMonth = boolean;
    },
    async handleFileValidate(event) {
      this.selectedFile = event.target.files[0];
      this.$refs['attachedFile'].value = '';
      this.serverValidationErrors = [];
      if (!this.selectedFile) return;
      try {
        this.aiRequestInProcess = true;
        this.startMessageQueue();
        const formData = new FormData();

        this.serverValidationErrors = [];

        formData.append('file', this.selectedFile);
        formData.append('brandId', this.brandId);
        const result  = await this.validateForecast(formData);
        this.unchangedForecast = { ...result.forecasts };
        this.isForecastChanged = false;
      } catch (error) {
        this.$toast.error('Something went wrong while request to AI assistant.');
      } finally {
        this.stopMessageQueue();
        this.selectedFile = null;
        this.aiRequestInProcess = false;
      }
    },
    async getForecastTemplate() {
      await this.downloadForecastTemplate();
    },
    dateFormatter(date) {
      return dateFormatter(date);
    },
    preselectDate() {
      const now = new Date();
      const options = { timeZone: 'America/New_York' };
      const nyTimeString = now.toLocaleString('en-US', options);
      const nyTime = new Date(nyTimeString);

      if (nyTime.getDate() < 15) {
        nyTime.setMonth(nyTime.getMonth() + 1);
      } else {
        nyTime.setMonth(nyTime.getMonth() + 2);
      }
      nyTime.setDate(1);

      const year = nyTime.getFullYear();
      const month = ('0' + (nyTime.getMonth() + 1)).slice(-2);
      const day = ('0' + nyTime.getDate()).slice(-2);
      const hours = ('0' + nyTime.getHours()).slice(-2);
      const minutes = ('0' + nyTime.getMinutes()).slice(-2);
      const seconds = ('0' + nyTime.getSeconds()).slice(-2);
      const milliseconds = ('00' + nyTime.getMilliseconds()).slice(-3);

      this.selectedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },
    startMessageQueue() {
      let index = 0;
      this.processingMessages.push(processingAIMessages[index]);
      index++;
      this.messageInterval = setInterval(() => {
        if (index < processingAIMessages.length) {
          this.processingMessages.push(processingAIMessages[index]);
          index++;
        } else {
          clearInterval(this.messageInterval);
        }
      }, 10000);
    },
    stopMessageQueue() {
      this.processingMessages = [];
      clearInterval(this.messageInterval);
    },
    async handleRecheckForecast() {
      const data = {
        forecasts: this.forecastAIData.forecasts,
        brandId: this.brandId
      };
      try {
        this.aiRequestInProcess = true;
        this.startMessageQueue();
        const result = await this.recheckForecast(data);
        this.unchangedForecast = { ...result.forecasts };
        this.isForecastChanged = false;
      } catch (error) {
        this.$toast.error('Something went wrong while request to AI assistant.');
      } finally {
        this.stopMessageQueue();
        this.aiRequestInProcess = false;
      }
    },
    async submitForecast() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const data = {
        forecasts: this.forecastAIData.forecasts,
        brandId: this.brandId,
        location: this.selectedLocation
      };
      try {
        this.aiRequestInProcess = true;
        this.startMessageQueue();
        await this.uploadAIForecast(data);
        this.isSubmitted = true;
      } catch (error) {
        const { errorDetails } = error.cause;
        this.serverValidationErrors = errorDetails;
        const errorToastMessage = 'Please fix the file.<br>';
        if (this.serverValidationErrors.length > 0) {
          this.$toast.error(errorToastMessage);
        } else {
          this.$toast.error(errorToastMessage + error);
        }
      } finally {
        this.stopMessageQueue();
        this.aiRequestInProcess = false;
      }
    }
  }
};
</script>

<style>
th, td {
  white-space: normal !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 3px 3px !important;
  font-size: 12px;
}

th {
  max-width: 150px;
  min-width: 80px;
  overflow-wrap: break-word;
  text-align: center;
}

td {
  max-width: 150px;
  overflow-wrap: break-word;
  white-space: normal !important;
}

th, td, input, textarea {
  text-align: center !important;
  vertical-align: middle !important;
  word-break: normal !important;
  padding: 3px 3px !important;
}

th:nth-child(1), td:nth-child(1) {
  min-width: 45px;
  max-width: 65px;
}

th:nth-child(2), td:nth-child(2) {
  min-width: 100px;
  max-width: 110px;
}

th:nth-child(10), td:nth-child(10) {
  min-width: 120px;
  max-width: 180px;
}
th:nth-child(13), td:nth-child(13) {
  min-width: 120px;
  max-width: 180px;
}

.table-responsive {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: auto;
  white-space: normal !important;
}

.table thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.table input, .table textarea {
  border: none;
  background: transparent;
  width: 100%;
  height: auto;
  white-space: normal !important;
  overflow-wrap: break-word;
  resize: none;
  font-size: 12px;
}

.table input:focus, .table textarea:focus {
  outline: none;
  border-bottom: 1px solid #007bff;
}

.blue-link {
  color: #0d6efd;
  text-decoration: underline;
  transition: color 0.3s, cursor 0.3s;
}

.blue-link:hover {
  cursor: pointer;
  color: #0f489c;
}

.chat-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-message {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 80%;
}

.system-message {
  align-self: flex-start;
}

.user-response {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-bottom: 15px;
}

.narrow-input {
  max-width: 200px !important;
  min-width: 160px;
}

.spinner-table-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.date-picker-component {
  height: 38px;
  min-height: 38px;
  max-height: 38px;
  display: flex;
  align-items: center;
}

.error-overlay {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  z-index: 100;
}

.error-card {
  background: rgba(255, 0, 0, 0.9);
  color: black;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}
</style>
